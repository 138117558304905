import React from "react"
import cx from "classnames"
import MuiButton from "@material-ui/core/Button"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => {
  const { info, warning } = theme.palette
  return {
    root: {
      color: theme.palette.common.white,
      padding: `${theme.spacing(0.75)}px ${theme.spacing(2.25)}px`,
      borderRadius: `${theme.spacing(7)}px`,
      backgroundColor: ({ bgColor }) =>
        bgColor === "warning" ? warning.main : info.main,
      "&:hover": {
        backgroundColor: ({ bgColor }) =>
          bgColor === "warning" ? warning.light : info.light,
      },
      "&:hover:active": {
        backgroundColor: ({ bgColor }) =>
          bgColor === "warning" ? warning.light : info.light,
      },
    },
  }
})

const CustomButton = ({ children, bgColor, ...props }) => {
  const classes = useStyles({ bgColor })
  return (
    <MuiButton {...props} className={cx(classes.root, props.className)}>
      {children}
    </MuiButton>
  )
}

CustomButton.defaultProps = {
  bgColor: "info", // 'info' | 'warning'
  variant: "contained",
  disableElevation: true,
}

export default CustomButton
