import React from "react"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

import Seo from "../components/seo"
import Layout from "../components/layout"
import Cloud from "../components/cloud"
import CloudsDesktop from "../images/clouds-dektop.svg"
import { CustomButton } from "../components/buttons"

const useStyles = makeStyles(theme => ({
  root: {
    padding: `${theme.spacing(20)}px 0`,
    position: "relative",
  },
  container: {
    position: "relative",
  },
  cloudsDesktop: {
    top: 0,
    left: "50%",
    position: "absolute",
    transform: "translateX(-50%)",
  },
  title: {
    marginTop: theme.spacing(3),
  },
  description: {
    marginTop: theme.spacing(3),
  },
  link: {
    textDecoration: "none !important",
  },
  button: {
    marginTop: theme.spacing(4),
  },
}))

const NotFoundPage = () => {
  const classes = useStyles()
  return (
    <Layout bgColor="#B136FF">
      <Seo title="404: Nie znaleziona" />
      <Box className={classes.root}>
        <CloudsDesktop className={classes.cloudsDesktop} />
        <Box my={10} className={classes.container}>
          <Cloud position="left" />
          <Container maxWidth="md">
            <Typography gutterBottom variant="h1" className={classes.title}>
              404: UPS, COŚ POSZŁO NIE TAK...
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
              className={classes.description}
            >
              Strona, której szukasz nie istnieje.
            </Typography>

            <Link to="/" className={classes.link}>
              <CustomButton size="small" className={classes.button}>
                Strona Główna
              </CustomButton>
            </Link>
          </Container>
        </Box>
        <Box className={classes.container}>
          <Cloud position="right" />
        </Box>
      </Box>
    </Layout>
  )
}

export default NotFoundPage
